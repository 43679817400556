import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';

import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia  from '@interness/theme-default/src/components/HeaderMedia';
import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Center       from '@interness/web-core/src/components/structure/Center/Center';
import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';


const GaragePage = (props) => {
  return (
    <>
      <HeaderMedia id="header-vorher-nachher"/>
      <Spacer/>
      <Wrapper>
        <Heading subtitle="Vorher / Nachher">Umbau eines Garagendaches</Heading>
        <h2>Garage vorher</h2>
        <Img fluid={props.data.images.images[0].file.localFile.childImageSharp.fluid}/>
        <Spacer/>
        <h2>Garage nachher</h2>
        <Img fluid={props.data.images.images[1].file.localFile.childImageSharp.fluid}/>
        <Spacer/>
        <Center>
          <CallToAction/>
        </Center>
      </Wrapper>
      <Spacer/>
    </>
  )
};

export default GaragePage;

export const query = graphql`
    query {
        images: directusMediaCollection(name: {eq: "vorher-nachher-garage"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;